import React from "react";
import Layout from "../components/Layout";
import PricingTable from "../components/PricingTable";

function News() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(https://images.pexels.com/photos/6335/man-coffee-cup-pen.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`}}>
                        <h1>Noticias</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/1b8cb33/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2Fc6%2F65%2F96f3a92d4d778db7a704584f1987%2Fistock-1152449705.jpg)'}}>

                            </div>
                        </div>
                        <div className="description">
                            <h1>Anexo 1-A de la RMISC 2020 con ajuste al pago de derechos por minería</h1>
                            <p>Apenas el 11 de diciembre de 2020 se publicó en el DOF la cuarta modificación a la RMISC
                                2020 en la que se precisaron dos cambios, el primero correspondiente a la regla
                                2.11.2.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/fiscal-contable/2020/12/14/anexo-1-a-de-la-rmisc-2020-con-ajuste-al-pago-de-derechos-por-mineria">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/4f25e4b/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F46%2F9b%2F509e1a88447da56dfa5652e4bfc4%2Facuerdos2.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Para qué sirven los Acuerdos Conclusivos de la Prodecon</h1>
                            <p>El Acuerdo Conclusivo es una herramienta para esclarecer o arreglar las diferencias o
                                desacuerdos que tenga un contribuyente con los auditores o visitadores sobre sus
                                ingresos, deducciones o cualquier otro tema relacionado con el cumplimiento de sus
                                obligaciones fiscales.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/fiscal-contable/2020/12/14/para-que-sirven-los-acuerdos-conclusivos-de-la-prodecon">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/90e15fe/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2Fa4%2F28%2F1b0d33db468aaeb2b68a39f7fac8%2Fistock-1166079140.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Ajuste anual de salarios y el CFDI</h1>
                            <p>Cálculo del ISR a cargo de los trabajadores por los ingresos pagados en el ejercicio que
                                concluye y cómo manifestar el impuesto anual en su comprobante</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/fiscal-contable/2020/12/07/ajuste-anual-de-salarios-y-el-cfdi">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/f52e677/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F33%2Fae%2Fcc027e304a6bbc8ab31171c3090e%2Fistock-897492288.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Reforma fiscal 2021</h1>
                            <p>Bitcoin es la mejor estrategia de salida de la opresión financiera después de Covid-19,
                                argumenta Keiser, ya que un economista dice que el balance de la Reserva Federal nunca
                                se reducirá.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/fiscal-contable/2020/11/24/reforma-fiscal-2021">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/7cdc86d/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F6a%2F3d%2F83b49d6c4627b6133007d0dfdb2a%2Fistock-1214251806.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>2020 el recuento en comercio exterior y aduanas</h1>
                            <p>El 2020 será recordado como un año difícil no solo para nuestro país sino para todo el
                                mundo, entre otras situaciones, por los estragos que trajo la epidemia de la enfermedad
                                generada por el virus SARS-CoV2 (Covid-19), situación q...</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://www.criptonoticias.com/mercados/jpmorgan-bitcoin-resiste-adversidades-mercados-tradicionales/">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/82490a7/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F67%2F5b%2F4eac42764d199f76cf288839cad0%2Fistock-896359692.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Criterios de utilidad en su defensa</h1>
                            <p>Selección de tesis aisladas y jurisprudencias de comercio exterior y aduanal emitidas en
                                2020 por el TFJA y la SCJN</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/comercio-exterior/2020/12/07/criterios-de-utilidad-en-su-defensa">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/d571d43/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2Feinfluss%2Fmedia%2F2016%2F03%2F04%2F%2Faduana.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Qué cuidar del valor en aduana</h1>
                            <p>Quienes pretendan introducir mercancías de procedencia extranjera al país deben pagar los
                                impuestos al comercio exterior (impuesto general de importación –IGI–), los
                                aprovechamientos y sus accesorios, y demás contribuciones domést...</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/comercio-exterior/2018/04/04/que-cuidar-del-valor-en-aduana">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/86fd415/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2Fd2%2F40%2F926bf72a4afea431123542f76e6a%2Fistock-1134237957.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Extienden México y EU restricción en frontera un mes más</h1>
                            <p>Las restricciones se mantendrán en los mismos términos en que se han desarrollado desde
                                su implementación, el pasado 21 de marzo</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/comercio-exterior/2020/12/11/extienden-mexico-y-eu-restriccion-en-frontera-un-mes-mas">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/580d017/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F53%2F94%2F1d28739e45c5933d20680495ee8a%2Fptu-reloj.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Certificados de origen a tramitar en diciembre</h1>
                            <p>La Secretaría de Economía sigue tomando medidas para mantener la operación de los
                                trámites de comercio exterior, y disminuir el riesgo de contagios entre sus trabajadores
                                y usuarios por el COVID-19.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/comercio-exterior/2020/12/09/certificados-de-origen-a-tramitar-en-diciembre">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://idc.brightspotcdn.com/dims4/default/4156615/2147483647/resize/800x%3E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F8a%2F54%2Fabed0eeb4e64988c280cabe67e18%2Fistock-621479656.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Ley de Ingresos de la Federación 2021</h1>
                            <p>Los ingresos “No petroleros” representan alrededor del 80 % de los ingresos totales que
                                se recibieron y que se estiman recibir por el Estado en ejercicios subsecuentes
                            </p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://idconline.mx/fiscal-contable/2020/11/17/ley-de-ingresos-de-la-federacion-2021">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default News
